import PropTypes from 'prop-types';
import { useEffect } from 'react';

const isStyleObject = (obj) => typeof obj === 'object';

const SingleOtpInput = ({
  className,
  disabledStyle,
  errorStyle,
  focus,
  focusStyle,
  hasErrored,
  index,
  inputStyle,
  isDisabled,
  isInputNum,
  isLastChild,
  placeholder,
  shouldAutoFocus,
  value,
  ...rest
}) => {
  useEffect(() => {
    const inputEl = document.getElementById(`otp-input-${index}`);

    if (inputEl && focus && shouldAutoFocus) {
      inputEl.focus();
    }
  }, [focus, shouldAutoFocus, index]);

  useEffect(() => {
    const inputEl = document.getElementById(`otp-input-${index}`);

    if (inputEl && focus) {
      inputEl.focus();
      inputEl.select();
    }
  }, [focus, index]);

  const getClasses = (...classes) =>
    classes.filter((c) => !isStyleObject(c) && c !== false).join(' ');

  return (
    <div
      className={className}
      style={{ display: 'flex', alignItems: 'center' }}
    >
      <input
        id={`otp-input-${index}`}
        aria-label={`${
          index === 0 ? 'Please enter verification code. ' : ''
        }Digit ${index + 1}`}
        autoComplete='off'
        style={{
          outline: 'none',
          textAlign: 'center',
          width: '1em',
          ...(isStyleObject(inputStyle) && inputStyle),
          ...(focus && isStyleObject(focusStyle) && focusStyle),
          ...(isDisabled && isStyleObject(disabledStyle) && disabledStyle),
          ...(hasErrored && isStyleObject(errorStyle) && errorStyle)
        }}
        placeholder={placeholder}
        className={getClasses(
          inputStyle,
          focus && focusStyle,
          isDisabled && disabledStyle,
          hasErrored && errorStyle
        )}
        type='tel'
        maxLength={1}
        disabled={isDisabled}
        value={value || ''}
        {...rest}
      />
    </div>
  );
};

export default SingleOtpInput;

SingleOtpInput.propTypes = {
  className: PropTypes.string,
  disabledStyle: PropTypes.object,
  errorStyle: PropTypes.string,
  focus: PropTypes.bool,
  focusStyle: PropTypes.object,
  hasErrored: PropTypes.bool,
  index: PropTypes.number,
  inputStyle: PropTypes.string,
  isDisabled: PropTypes.bool,
  isInputNum: PropTypes.bool,
  isLastChild: PropTypes.bool,
  placeholder: PropTypes.string,
  shouldAutoFocus: PropTypes.bool,
  value: PropTypes.string
};
