import { PropTypes } from 'prop-types';

const Text = ({ className = '', content, HtmlTag = 'div', ...rest }) => (
  <HtmlTag {...{ className, ...rest }}>{content}</HtmlTag>
);

export default Text;

Text.propTypes = {
  className: PropTypes.string,
  HtmlTag: PropTypes.string
};
