import dynamic from 'next/dynamic';
import Image from 'next/image';
import { useState } from 'react';

import { staticMediaStoreBaseURL } from '@/config/common';

const SelectNoSSR = dynamic(() => import('react-select'), { ssr: false });

const customStyles = {
  dropdownIndicator: (base, state) => ({
    ...base,
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0)',
    transition: '250ms'
  }),
  control: (base) => ({
    ...base,
    border: 'none',
    boxShadow: 'none',
    '&:hover': { border: 'none' },
    height: 56,
    minHeight: 56
  }),
  option: (base, { isFocused, isSelected }) => ({
    ...base,
    backgroundColor: isFocused ? '#FDF1EE' : 'white',
    color: isSelected || '#000'
  })
};

const PlaceholderComponent = ({ placeholderText }) => (
  <div className='flex gap-2'>
    <Image
      alt='search'
      height={0}
      src={`${staticMediaStoreBaseURL}/icons/search.svg`}
      style={{ width: 18, height: 18 }}
      width={0}
    />
    <div>{placeholderText}</div>
  </div>
);

const EnumController = ({
  clearErrors,
  dbName,
  disabled,
  getOptionLabel,
  getOptionValue,
  options,
  placeholderLabel,
  register,
  selectedOption,
  selectedValue,
  setValue,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const customPlaceholder = isOpen ? (
    <PlaceholderComponent
      {...{ placeholderText: `Search ${placeholderLabel}` }}
    />
  ) : (
    `Select ${placeholderLabel}`
  );

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <SelectNoSSR
      instanceId={`select-box-${dbName}`}
      {...{
        ...register(dbName),
        ...rest,
        onChange: (e) => {
          setValue(dbName, e);
          clearErrors();
        },
        classNamePrefix: 'select2-selection form-select',
        defaultValue: selectedOption || selectedValue,
        getOptionLabel,
        getOptionValue,
        isDisabled: disabled,
        menuPlacement: 'top',
        onMenuClose: toggleMenu,
        onMenuOpen: toggleMenu,
        options,
        placeholder: customPlaceholder,
        styles: customStyles,
        value: selectedOption
      }}
    />
  );
};

const SelectFormInput = ({
  clearErrors,
  className,
  control,
  dbName,
  disabled = false,
  errors,
  getOptionLabel,
  getOptionValue,
  getValues,
  name = '',
  placeholderLabel = '',
  register,
  setValue,
  values,
  width = 'w-40',
  ...rest
}) => {
  const selectedValue = getValues(dbName);
  const selectedOption = values.find(
    (obj) => obj[getOptionValue] === selectedValue
  );

  return (
    <>
      <div className={`${className} multi-select ${width}`}>
        <EnumController
          {...{
            clearErrors,
            control,
            dbName,
            disabled,
            getOptionLabel,
            getOptionValue,
            name,
            options: values,
            placeholderLabel,
            register,
            selectedOption,
            selectedValue,
            setValue,
            ...rest
          }}
        />
      </div>
      {errors[dbName] && (
        <div className='mt-1 w-full text-red-500 text-xs font-medium capitalize invalid-feedback'>
          {errors[dbName].message}
        </div>
      )}
    </>
  );
};

export default SelectFormInput;
