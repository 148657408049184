import _ from 'lodash';
import { useEffect } from 'react';
import { useMenu } from 'react-instantsearch';
import { createInstantSearchRouterNext } from 'react-instantsearch-router-nextjs';

import { DEFAULT_TAB_CATEGORY } from '@/config/searchkit/bidList';
import { GENERIC_ALL_TAB_CATEGORY_LABEL } from '@/config/searchkit/common';
import { getMenuFromCurrentURL } from '@/helpers/searchkit';

const tabStyle =
  'bg-white text-sm md:text-base my-[6px] cursor-pointer font-medium flex rounded-lg shadow-card';
const tabLabelStyle =
  'text-dim-gray md:text-black searchkit-list-label text-nowrap';
const tabCountStyle = `ais-Menu-count searchkit-menu-list-count text-xs font-semibold rounded-full flex justify-center items-center align-middle bg-light-red text-brand border-none min-w-6`;

const handleMenuClick = ({ key, refine, setSelectedMenu }) => {
  setSelectedMenu((prevSelectedValue) => {
    const unSelectActiveTabCategory = prevSelectedValue === key;
    return unSelectActiveTabCategory ? DEFAULT_TAB_CATEGORY : key;
  });
  refine(key);
};

const AllTabMenu = ({
  allMenuCount,
  handleAllMenuClick,
  isExclusiveTabSelected
}) => (
  <li
    key={GENERIC_ALL_TAB_CATEGORY_LABEL}
    className={`${tabStyle} ${
      isExclusiveTabSelected ? '' : 'selected-menu-tab'
    } `}
  >
    <div
      {...{
        className: 'flex p-3',
        onClick: handleAllMenuClick
      }}
    >
      <span className={tabLabelStyle}>All</span>
      <span className={tabCountStyle}>{allMenuCount}</span>
    </div>
  </li>
);

const ExclusiveTabsMenu = ({
  enrichedTabCategoryConfigList,
  refine,
  setSelectedMenu
}) =>
  enrichedTabCategoryConfigList.map(({ label, key, isRefined, count }, id) => (
    <li
      key={`${key}_${id}`}
      className={`${tabStyle} ${isRefined ? 'selected-menu-tab' : ''} `}
    >
      <div
        className='flex p-3'
        onClick={() =>
          !isRefined &&
          handleMenuClick({
            key,
            refine,
            setSelectedMenu
          })
        }
      >
        <span className={tabLabelStyle}>{label}</span>
        <span className={tabCountStyle}>{count}</span>
      </div>
    </li>
  ));

const ListTypeMenuWithAllTab = ({
  attribute,
  index,
  selectedMenu,
  setSelectedMenu,
  tabCategoryConfigList
}) => {
  const { items, refine } = useMenu({ attribute });

  const instantSearchRouter = createInstantSearchRouterNext({
    routerOptions: {
      cleanUrlOnDispose: false
    }
  });

  const handlePopState = () => {
    getMenuFromCurrentURL({
      attribute,
      index,
      instantSearchRouter,
      setSelectedTab: setSelectedMenu
    });
  };

  useEffect(() => {
    handlePopState();
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const enrichedTabCategoryConfigList = tabCategoryConfigList
    .map(({ label, key: expectedBucketValue }) => {
      const itemOfTabCategory = items.find(
        ({ value: bucketValue }) => expectedBucketValue === bucketValue
      );
      const defaultEnrichedTabCategoryConfig = {
        count: 0,
        data: null,
        exhaustive: true,
        isRefined: selectedMenu === expectedBucketValue,
        key: expectedBucketValue,
        label
      };
      const itemOfTabCategoryExists = !(
        _.isNull(itemOfTabCategory) || _.isEmpty(itemOfTabCategory)
      );
      if (itemOfTabCategoryExists) {
        return {
          ...itemOfTabCategory,
          key: itemOfTabCategory.value
        };
      }

      return defaultEnrichedTabCategoryConfig;
    })
    .filter(Boolean);

  const isExclusiveTabSelected = enrichedTabCategoryConfigList.some(
    ({ isRefined }) => isRefined
  );

  const allMenuCount = items.reduce(
    (accumulator, { count }) => accumulator + count,
    0
  );

  const handleAllMenuClick = () => {
    if (isExclusiveTabSelected) {
      setSelectedMenu(GENERIC_ALL_TAB_CATEGORY_LABEL);
      refine('');
    }
  };

  return (
    <div className='-mt-8 mx-6 md:mt-0 md:mx-0 searchkit-list-menu md:shadow md:rounded-lg md:px-2'>
      <ul className='flex gap-3 items-center overflow-auto'>
        <AllTabMenu
          {...{
            allMenuCount,
            handleAllMenuClick,
            isExclusiveTabSelected
          }}
        />
        <ExclusiveTabsMenu
          {...{ enrichedTabCategoryConfigList, refine, setSelectedMenu }}
        />
      </ul>
    </div>
  );
};

export default ListTypeMenuWithAllTab;
