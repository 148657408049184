import _ from 'lodash';

import { BID_LIST_INDEX } from '@/config/common';
import { GENERIC_ALL_TAB_CATEGORY_LABEL } from '@/config/searchkit/common';

export const TAB_CATEGORY_ENUM = {
  ALL: 'ALL',
  NEW: 'NEW',
  IN_PROGRESS: 'IN_PROGRESS',
  SUBMITTED: 'SUBMITTED',
  EXPIRED: 'EXPIRED'
};

export const DEFAULT_TAB_CATEGORY = TAB_CATEGORY_ENUM.ALL;

export const TAB_CATEGORY_CONFIG_LIST = [
  {
    className: 'text-bluish-cyan',
    key: TAB_CATEGORY_ENUM.NEW,
    label: 'New',
    sortOrder: 0
  },
  {
    className: 'in-progress-badge',
    key: TAB_CATEGORY_ENUM.IN_PROGRESS,
    label: 'In-Progress',
    sortOrder: 1
  },
  {
    className: 'submitted-badge',
    key: TAB_CATEGORY_ENUM.SUBMITTED,
    label: 'Submitted',
    sortOrder: 2
  },
  {
    className: 'past-badge',
    key: TAB_CATEGORY_ENUM.EXPIRED,
    label: 'Past',
    sortOrder: 3
  }
]
  .sort((a, b) => a.sortOrder - b.sortOrder)
  .map((a) => _.omit(a, 'sortOrder'));

export const SEARCHABLE_ATTRIBUTES = [
  {
    esDocKey: null,
    key: GENERIC_ALL_TAB_CATEGORY_LABEL,
    label: 'All',
    sortOrder: 0
  },
  {
    esDocKey: 'eventLocation',
    key: 'EVENT_LOCATION',
    label: 'Event Location',
    sortOrder: 1
  },
  {
    esDocKey: 'quoteNumber',
    key: 'QUOTE_NUMBER',
    label: 'Quote Number',
    sortOrder: 2
  }
]
  .sort((a, b) => a.sortOrder - b.sortOrder)
  .map((a) => _.omit(a, 'sortOrder'));

export const SORT_BY_ITEMS_BID_LIST = [
  {
    label: 'Last Updated at: Newest first',
    sortOrder: 0,
    value: BID_LIST_INDEX
  },
  {
    label: 'Last Updated at: Oldest first',
    sortOrder: 1,
    value: `${BID_LIST_INDEX}_bidUpdatedAt_asc`
  },
  {
    label: 'Event Date: Newest first',
    sortOrder: 2,
    value: `${BID_LIST_INDEX}_event_desc`
  },
  {
    label: 'Event Date: Oldest first',
    sortOrder: 3,
    value: `${BID_LIST_INDEX}_event_asc`
  }
]
  .sort((a, b) => a.sortOrder - b.sortOrder)
  .map((a) => _.omit(a, 'sortOrder'));
