import { ORDER_STATUS } from '@/config/common';

const getStatusSpecificClassName = ({ status }) => {
  const dictionary = { orderStatusSpecificClassName: '' };
  switch (status) {
    case ORDER_STATUS.CANCELLED.value:
      dictionary.orderStatusSpecificClassName = 'cancelled-order';
      break;
    default:
      break;
  }
  return dictionary;
};

const OrderDetailSectionTitle = ({ title, status }) => {
  const { orderStatusSpecificClassName } = getStatusSpecificClassName({
    status
  });
  return (
    <div
      className={`text-base md:text-xl font-medium mx-5 md:mx-0 my-4 md:my-6 ${orderStatusSpecificClassName}`}
    >
      {title}
    </div>
  );
};

export default OrderDetailSectionTitle;
