import PropTypes from 'prop-types';

const CTA = ({
  buttonColor = 'bg-gradient-to-r from-lightOrange via-darkOrange to-rose text-white h-11 px-8 md:py-4 text-base rrfq-button w-full md:w-auto',
  children,
  disabled = false,
  onclick,
  ...props
}) => {
  const className = `md:h-auto ${buttonColor}
   focus:outline-none rounded-lg `;
  return (
    <button
      {...{
        ...props,
        disabled,
        className: [className, props.className || ''].join(' ').trim()
      }}
    >
      {children}
    </button>
  );
};

export default CTA;

CTA.propTypes = {
  buttonColor: PropTypes.string,
  children: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onclick: PropTypes.func
};
