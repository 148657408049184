import { Menu } from 'react-instantsearch';

import { getBucketsSortedByTabOrder } from '@/helpers/searchkit';

const ListTypeMenu = ({ attribute, tabCategoryKeysSorted }) => (
  <div className='-mt-8 mx-6 md:mx-0 searchkit-list-menu'>
    <Menu
      {...{
        attribute,
        classNames: {
          ...{
            count: `searchkit-menu-list-count text-xs font-semibold rounded-full flex justify-center items-center align-middle bg-light-red text-brand border-none`,
            item: 'bg-white p-3 md:p-4 text-sm md:text-base rounded-lg shadow-card my-2 cursor-pointer font-medium',
            label: 'text-dim-gray searchkit-list-label',
            list: 'flex gap-3 items-center overflow-auto md:justify-center',
            root: 'items-center',
            selectedItem:
              'searchkit-list-item text-brand border-b-2 border-brand'
          }
        },
        field: attribute,
        id: 'list-type-menu',
        transformItems: (buckets, e) =>
          getBucketsSortedByTabOrder({
            attribute,
            buckets,
            e,
            tabCategoryKeysSorted
          })
      }}
    />
  </div>
);

export default ListTypeMenu;
