import PropTypes from 'prop-types';

const TextKeyValuePair = ({
  className = 'flex-col gap-2',
  label,
  labelClass = 'text-silver uppercase text-xs md:text-sm',
  show = true,
  spaceTop = 'mt-2 md:mt-0',
  value,
  valueClassName = 'text-base md:text-lg'
}) =>
  show && (
    <div className={`flex ${className} ${spaceTop}`}>
      <div className={labelClass}>{label}</div>
      <div className={valueClassName}>{value}</div>
    </div>
  );

export default TextKeyValuePair;

TextKeyValuePair.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
