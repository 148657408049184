import NextNProgress from 'nextjs-progressbar';

const ProgressBar = () => (
  <NextNProgress
    {...{
      color: '#ff794d',
      height: 8,
      startPosition: 0.1,
      stopDelayMs: 100
    }}
  />
);

export default ProgressBar;
