import Image from 'next/image';

import { staticMediaStoreBaseURL } from '@/config/common';

const Loader = () => (
  <div className='overlayer h-screen flex'>
    <Image
      alt='loading image'
      className='self-center mx-auto w-24'
      height={0}
      priority={true}
      src={`${staticMediaStoreBaseURL}/icons/loading.gif`}
      style={{ width: 60, height: 60 }}
      width={0}
    />
  </div>
);

export default Loader;
