import Image from 'next/image';

import { staticMediaStoreBaseURL } from '@/config/common';

const CloseIcon = ({
  className = 'flex justify-end cursor-pointer',
  iconHeight = 20,
  iconClassName,
  onClick = () => {},
  iconWidth = 20,
  icon = 'close-with-border-gray.svg',
  ...rest
}) => (
  <span
    className={className}
    onClick={onClick}
  >
    <Image
      {...{
        alt: 'cross',
        className: iconClassName,
        height: 0,
        src: `${staticMediaStoreBaseURL}/icons/${icon}`,
        style: { width: iconWidth, height: iconHeight },
        width: 0,
        ...rest
      }}
    />
  </span>
);

export default CloseIcon;
