import ReactDatePicker from 'react-datepicker';
import { useConnector } from 'react-instantsearch';

import { convertToDateFormat } from '@/lib/dateAndTime';

const CustomDatePicker = ({ refine, selectedDate, setSelectedDate }) => (
  <ReactDatePicker
    {...{
      selected: selectedDate ? new Date(selectedDate) : '',
      open: true,
      placeholderText: 'select date',
      className: 'searchkit-date-picker form-control',
      dayClassName: (date) => {
        const today = new Date();
        return date.getMonth() === today.getMonth() &&
          date.getDate() === selectedDate
          ? 'searchkit-datepicker-selected-day'
          : 'searchkit-datepicker-day';
      },
      dateFormat: 'dd-MM-yyyy',
      disabled: false,
      onChange: async (e) => {
        setSelectedDate(e);
        await refine(convertToDateFormat(e, 'YYYY-MM-DD'));
      }
    }}
  />
);

const noop = () => {};

const connectDateRefinementList = (renderFn, unmountFn = noop) =>
  function dateRefinementList(widgetParams) {
    const { attribute } = widgetParams;
    const connectorState = {};
    return {
      $$type: 'partner.dateRefinementList',
      getWidgetRenderState({ results, helper }) {
        if (!connectorState.refine) {
          connectorState.refine = (value) =>
            helper
              .clearRefinements(attribute)
              .addFacetRefinement(attribute, value)
              .search();
        }
        if (!results) {
          return { items: [], refine: connectorState.refine, widgetParams };
        }
        const items = results.getFacetValues(attribute) || [];
        return {
          items,
          refine: connectorState.refine,
          widgetParams
        };
      },
      init(initOptions) {
        const { instantSearchInstance } = initOptions;
        renderFn(
          {
            ...this.getWidgetRenderState(initOptions),
            instantSearchInstance
          },
          true
        );
      },
      render(renderOptions) {
        const { instantSearchInstance } = renderOptions;
        renderFn(
          {
            ...this.getWidgetRenderState(renderOptions),
            instantSearchInstance
          },
          false
        );
      },
      dispose() {
        unmountFn();
      },
      getWidgetUiState(uiState, { searchParameters }) {
        return {
          ...uiState,
          dateRefinementList: {
            ...uiState.dateRefinementList,
            [attribute]: searchParameters.getExcludeRefinements(attribute)
          }
        };
      },
      getWidgetSearchParameters(searchParameters, { uiState }) {
        const state = searchParameters.addFacet(attribute);
        const values = uiState.dateRefinementList?.[attribute];

        if (Array.isArray(values)) {
          return values.reduce(
            (acc, curr) => acc.addExcludeRefinement(attribute, curr),
            state
          );
        }
        return state;
      }
    };
  };

const useDateRefinementWidgetList = (props, additionalWidgetProperties) =>
  connectDateRefinementList(props, additionalWidgetProperties);

const useDateRefinementWidget = (props) =>
  useConnector(useDateRefinementWidgetList, props, {
    $$widgetType: 'partner.dateRefinementList'
  });

const DateFilterWithDatePicker = (props) => {
  const data = useDateRefinementWidget(props);
  return (
    <div className='searchkit-datepicker-container'>
      <CustomDatePicker {...{ ...data, ...props }} />
    </div>
  );
};

export default DateFilterWithDatePicker;
