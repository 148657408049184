import { getStatusBasedClass } from '@/services/order.service';

const PageHeader = ({
  className = 'mt-12 mx-auto',
  height = 'h-40',
  rounded = 'rounded-b-4xl',
  status,
  title
}) => {
  const { orderStatusSpecificClassName } = getStatusBasedClass({ status });
  return (
    <div
      className={`${orderStatusSpecificClassName} ${height} ${rounded} px-5 md:px-8 pt-4`}
    >
      <div
        className={`font-semibold text-lg md:text-2xl text-white uppercase text-center ${className}`}
      >
        {title}
      </div>
    </div>
  );
};

export default PageHeader;
