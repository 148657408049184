import Image from 'next/image';
import { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';

import ImageCarouselModal from '@/components/atomic/nuclei';
import { getImageURL, getRankedImages } from '@/helpers/carousel';
import { useIsMobile } from '@/lib/screenResolution';
import { noProductImageURL } from '@/services/partnerPortal.service';

const onImageClick = ({ image, openImageModal }) => {
  openImageModal(image);
};

const CustomThumbnail = ({ image, openImageModal }) => {
  const [imageURL, setImageURL] = useState(getImageURL(image));
  return (
    <div
      onClick={() =>
        onImageClick({
          image,
          openImageModal
        })
      }
    >
      <picture className='flex items-center'>
        <source
          data-srcset={image.url}
          type='image/jpg'
        />
        <Image
          alt='image thumbnail'
          height={0}
          onError={() => setImageURL(noProductImageURL)}
          src={imageURL}
          style={{ width: 76, height: 50 }}
          width={0}
        />
      </picture>
    </div>
  );
};

const CustomThumbnails = ({ images = [], openImageModal }) =>
  images.map((image, index) => (
    <CustomThumbnail
      key={index}
      {...{
        image,
        openImageModal
      }}
    />
  ));

const CarouselImage = ({
  containerStyle,
  excludeImageHeightFromContainer,
  height,
  image,
  openImageModal,
  preventOnCarouselImageClick,
  style,
  width
}) => {
  const className = excludeImageHeightFromContainer
    ? `relative overflow-hidden ${containerStyle}`
    : `relative ${height} overflow-hidden ${containerStyle}`;

  const [imageURL, setImageURL] = useState(getImageURL(image));

  return (
    <div
      className={className}
      key={`${image.url}`}
      onClick={() =>
        !preventOnCarouselImageClick &&
        onImageClick({
          image,
          openImageModal
        })
      }
    >
      <Image
        {...{
          alt: 'image',
          className: style,
          height,
          fill: true,
          onError: () => setImageURL(noProductImageURL),
          sizes: '(max-width: 600px) 100vw, 50vw',
          src: imageURL,
          width
        }}
      />
    </div>
  );
};

const ImageCarousel = ({
  containerStyle,
  excludeImageHeightFromContainer = false,
  height,
  images: imagesRaw = [],
  isThumbnail = true,
  layout,
  listing,
  objectFit,
  objectPosition,
  preventOnCarouselImageClick = false,
  style,
  width
}) => {
  const [isFocus, setIsFocus] = useState(false);
  const [slidePosition, setSlidePosition] = useState(0);

  const [modalImage, setModalImage] = useState(false);

  const [isMobile] = useIsMobile();

  const openImageModal = (image) => {
    setModalImage(image);
  };

  const images = getRankedImages(imagesRaw);

  return (
    <div
      className='relative'
      onMouseEnter={() => {
        setIsFocus(true);
        setSlidePosition(1);
      }}
      onMouseLeave={() => {
        setIsFocus(false);
        setSlidePosition(0);
      }}
    >
      <Carousel
        {...{
          autoPlay: isFocus,
          infiniteLoop: true,
          interval: !listing ? 5000 : 1500,
          renderThumbs: () =>
            isThumbnail && CustomThumbnails({ images, openImageModal }),
          selectedItem: slidePosition,
          showArrows: false,
          showIndicators: images.length > 1,
          showStatus: false,
          showThumbs: !isMobile && !listing,
          transitionTime: 800
        }}
      >
        {images.map((image, index) => (
          <CarouselImage
            key={index}
            {...{
              containerStyle,
              excludeImageHeightFromContainer,
              height,
              image,
              layout,
              objectFit,
              objectPosition,
              openImageModal,
              preventOnCarouselImageClick,
              style,
              width
            }}
          />
        ))}
      </Carousel>
      {modalImage && (
        <ImageCarouselModal
          {...{
            images,
            modalImage,
            openImageModal
          }}
        />
      )}
    </div>
  );
};

export default ImageCarousel;
