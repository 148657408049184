import Image from 'next/image';
import { useEffect, useState } from 'react';
import { Modal } from 'reactstrap';

import { NO_PRODUCT_IMAGE, staticMediaStoreBaseURL } from '@/config/common';
import { getImageURL, getRankedImages } from '@/helpers/carousel';
import { useIsMobile } from '@/lib/screenResolution';
import { noProductImageURL } from '@/services/partnerPortal.service';

const backIcon = `${staticMediaStoreBaseURL}/icons/back-white.svg`;
const closeIcon = `${staticMediaStoreBaseURL}/icons/close-with-border-white.svg`;

const PreviousNextIcon = ({ images, isMobile, previousNextIconPair }) =>
  images.length > 1 && (
    <div className='absolute w-full z-101 top-2/4 px-6 md:px-14 flex justify-between text-6xl'>
      {previousNextIconPair.map(
        ({ name, onClick, imageProps: { className, src, alt } }, id) => (
          <span
            key={id}
            {...{
              id: name,
              className: 'cursor-pointer text-white w-9',
              onClick
            }}
          >
            <Image
              {...{
                alt,
                className,
                height: 0,
                id: name,
                src,
                style: {
                  width: isMobile ? 28 : 36,
                  height: isMobile ? 28 : 36
                },
                width: 0
              }}
            />
          </span>
        )
      )}
    </div>
  );

const ImageTitle = ({ imageTitle }) =>
  imageTitle && (
    <div className='mt-0 md:mt-0 self-center'>
      <h3 className='text-white text-sm md:text-lg font-medium font-Montserrat self-center'>
        {imageTitle}
      </h3>
    </div>
  );

const CarouselModalHeaderSection = ({
  imageTitle,
  isMobile,
  openImageModal
}) => (
  <div className='absolute px-6 md:px-16 z-101 text-white top-4 mb-2 md:top-4 w-full justify-content-end flex'>
    <div className='h-7 flex justify-between w-full'>
      <div className='flex gap-4'>
        <Image
          alt='backIcon'
          className='z-101 h-full cursor-pointer'
          height={0}
          onClick={() => openImageModal(false)}
          src={backIcon}
          style={{
            width: isMobile ? 14 : 16,
            height: isMobile ? 12 : 28
          }}
          width={0}
        />
        <ImageTitle {...{ imageTitle }} />
      </div>
      <div>
        <Image
          alt='closeIcon'
          className='z-101 h-full cursor-pointer'
          height={0}
          onClick={() => openImageModal(false)}
          src={closeIcon}
          style={{ width: 20, height: 20 }}
          width={0}
        />
      </div>
    </div>
  </div>
);

const CarouselModalImage = ({ imageUrl, pageCountTitle }) => {
  const [imageURL, setImageURL] = useState(imageUrl);
  useEffect(() => {
    setImageURL(imageUrl);
  }, [imageUrl]);
  return (
    <div className='relative carousel-modal-image flex flex-col items-center md:mx-auto rounded-lg  w-full md:w-9/10 h-5/6'>
      <Image
        alt='image'
        className='product-image'
        fill={true}
        onError={() => setImageURL(noProductImageURL)}
        src={imageURL}
        unoptimized={true}
      />
      <div className='text-white fixed bottom-10 text-lg font-medium z-50'>
        {pageCountTitle}
      </div>
    </div>
  );
};

const ImageCarouselBody = ({
  handleTouchEnd,
  handleTouchMove,
  handleTouchStart,
  images,
  imageTitle,
  imageUrl,
  isMobile,
  openImageModal,
  pageCountTitle,
  previousNextIconPair
}) => (
  <div
    className='fixed z-50 w-full h-full inset-0 bg-black bg-opacity-90 transition-opacity pt-10'
    onTouchEnd={handleTouchEnd}
    onTouchMove={handleTouchMove}
    onTouchStart={handleTouchStart}
  >
    <div className='w-full h-full flex flex-col my-20'>
      <CarouselModalHeaderSection
        {...{ imageTitle, isMobile, openImageModal }}
      />
      <PreviousNextIcon {...{ images, isMobile, previousNextIconPair }} />
      <CarouselModalImage {...{ imageUrl, pageCountTitle }} />
    </div>
  </div>
);

const getIndex = ({ images, modalImage }) =>
  modalImage.rank
    ? modalImage.rank - 1
    : images.findIndex(({ url }) => url === modalImage.url);

const getRequestedImage = ({ imageRequested, setImage }) => {
  setImage(imageRequested);
};

const ImageCarouselModal = ({
  images: imagesRaw = [NO_PRODUCT_IMAGE],
  modalImage = NO_PRODUCT_IMAGE,
  openImageModal
}) => {
  const images = getRankedImages(imagesRaw);
  const [isMobile] = useIsMobile();
  const [selectedImageIndex, setSelectedImageIndex] = useState(
    getIndex({ modalImage, images })
  );
  const [image, setImage] = useState(modalImage);

  useEffect(() => {
    const imageRequested = images[selectedImageIndex] || NO_PRODUCT_IMAGE;
    getRequestedImage({ imageRequested, setImage });
  }, [selectedImageIndex]);

  const previous = () => {
    setSelectedImageIndex((currentIndex) =>
      currentIndex ? currentIndex - 1 : images.length - 1
    );
  };
  const next = () => {
    setSelectedImageIndex((currentIndex) =>
      currentIndex === images.length - 1 ? 0 : currentIndex + 1
    );
  };
  const eventKeyObject = {
    ArrowLeft: () => previous(),
    ArrowRight: () => next(),
    Escape: () => openImageModal(false)
  };

  const keyPressEventAction = ({ key: keyPressed }) =>
    eventKeyObject[keyPressed] && eventKeyObject[keyPressed]();

  useEffect(() => {
    document.addEventListener('keyup', keyPressEventAction);
    return () => document.removeEventListener('keyup', keyPressEventAction);
  }, []);

  let positionStart = 0;
  let positionEnd = 0;
  const handleTouchStart = (event) => {
    positionStart = event.targetTouches[0].clientX;
  };
  const handleTouchMove = (event) => {
    positionEnd = event.targetTouches[0].clientX;
  };
  const handleTouchEnd = (event) => {
    if (!(event.target.id === 'previous' || event.target.id === 'next')) {
      if (positionStart < positionEnd) {
        previous();
      } else {
        next();
      }
    }
  };

  const surfaceSpecificPathStub = isMobile ? '/mobile-icons' : '/web-icons';
  const nextIcon = `${staticMediaStoreBaseURL}${surfaceSpecificPathStub}/next.svg`;
  const previousIcon = `${staticMediaStoreBaseURL}${surfaceSpecificPathStub}/previous.svg`;

  const previousNextIconPair = [
    {
      imageProps: {
        alt: 'previousIcon',
        className: 'text-white z-101',
        src: previousIcon
      },
      name: 'previous',
      onClick: previous
    },
    {
      imageProps: {
        alt: 'nextIcon',
        className: 'z-101',
        src: nextIcon
      },
      name: 'next',
      onClick: next
    }
  ];

  const pageCountTitle = `${image.rank}/${images.length}`;
  const imageUrl = getImageURL(image);

  return (
    imageUrl && (
      <Modal
        centered={true}
        className='p-0 product-image-modal'
        isOpen={true}
        size='xl'
      >
        <ImageCarouselBody
          {...{
            handleTouchEnd,
            handleTouchMove,
            handleTouchStart,
            images,
            imageTitle: image.imageTitle || '',
            imageUrl,
            isMobile,
            openImageModal,
            pageCountTitle,
            previousNextIconPair
          }}
        />
      </Modal>
    )
  );
};

export default ImageCarouselModal;
