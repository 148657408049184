import Image from 'next/image';

import { staticMediaStoreBaseURL } from '@/config/common';

const IconWithLabel = ({
  containerClass,
  imgAlt,
  imageContainerClass = '',
  imgHeight = 24,
  imgSrc,
  imgWidth = 24,
  value,
  valueStyle
}) => (
  <div className={containerClass}>
    <div className={imageContainerClass}>
      <Image
        alt={imgAlt}
        height={0}
        src={`${staticMediaStoreBaseURL}/icons/${imgSrc}`}
        style={{
          width: imgWidth,
          height: imgHeight
        }}
        width={0}
      />
    </div>
    <div className={valueStyle}>{value}</div>
  </div>
);

export default IconWithLabel;
