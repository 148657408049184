import { useRouter } from 'next/router';

const defaultClass = 'cursor-pointer';

const CustomAnchor = ({
  href,
  onClick,
  target,
  children,
  className = '',
  ...restAnchorProps
}) => {
  const router = useRouter();

  const onClickModified = () => {
    if (typeof onClick === 'function') {
      return onClick();
    }
    if (href) {
      if (target) {
        return window.open(href, '_blank');
      }
      return router.push(href);
    }
    return true;
  };

  return (
    <span
      {...{
        onClick: onClickModified,
        className: `${defaultClass} ${className}`,
        ...restAnchorProps
      }}
    >
      {children}
    </span>
  );
};

export default CustomAnchor;
