import Image from 'next/image';

import { staticMediaStoreBaseURL } from '@/config/common';

const PageNotFound = ({
  className = 'h-screen',
  heading,
  imgSrc = '404.svg',
  subDivClassName = 'align-center',
  subHeading = ''
}) => (
  <div className={`${className} flex justify-center text-center gap-4`}>
    <div className={`${subDivClassName} w-full px-6`}>
      <div>
        <Image
          alt='not found'
          className='mx-auto w-auto h-auto'
          height={0}
          src={`${staticMediaStoreBaseURL}/icons/${imgSrc}`}
          style={{ width: 260, height: 260 }}
          width={0}
        />
      </div>
      <div className='text-base md:text-2xl text-nero font-medium mt-3'>
        {heading}
      </div>
      <div className='text-sm md:text-base text-nero font-light md:mt-2'>
        {subHeading}
      </div>
    </div>
  </div>
);

export default PageNotFound;
