import Image from 'next/image';
import PropTypes from 'prop-types';

import { staticMediaStoreBaseURL } from '@/config/common';

const InputField = ({
  baseClass = 'w-full border text-gray-900 px-2 py-2 outline-none placeholder:text-grey-400',
  className = '',
  dbName,
  disabled,
  errorClass = 'flex-1',
  errorMessageClassName = 'text-xs text-red-500',
  errors,
  inputGroup,
  isShowErrorIcon = false,
  label = '',
  labelClass = 'text-nero py-1',
  register,
  ...rest
}) => {
  const isInvalidMessage = errors?.[dbName]?.message;
  return (
    <div className={inputGroup}>
      {label && <label className={labelClass}>{label}</label>}
      <div className={errorClass}>
        <input
          {...{
            ...register,
            className: `${baseClass} ${className} ${
              disabled ? 'cursor-not-allowed' : 'cursor-pointer'
            }`,
            disabled,
            onWheel: (e) => e.target.blur(),
            ...rest
          }}
        />
        {isInvalidMessage && (
          <div className={errorMessageClassName}>
            {isShowErrorIcon && (
              <div>
                <Image
                  alt='alert'
                  className='cursor-pointer'
                  height={0}
                  src={`${staticMediaStoreBaseURL}/icons/alert-white.svg`}
                  style={{ width: 14, height: 14 }}
                  width={0}
                />
              </div>
            )}
            {isInvalidMessage}
          </div>
        )}
      </div>
    </div>
  );
};

export default InputField;

InputField.propTypes = {
  className: PropTypes.string,
  dbName: PropTypes.string,
  disabled: PropTypes.bool,
  errorClass: PropTypes.string,
  errors: PropTypes.object,
  inputGroup: PropTypes.string,
  label: PropTypes.string,
  labelClass: PropTypes.string,
  register: PropTypes.object
};
