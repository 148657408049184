import { CurrentRefinements } from 'react-instantsearch';

import { transformCurrentRefinementItemsLabelForOrderListing } from '@/helpers/searchkit';
import {
  LOCAL_STORAGE_KEYS,
  removeLocalStorageValue
} from '@/services/localStorage.service';

const currentRefinementClassNameGroup = {
  category:
    'border-platinum border px-2 py-1 rounded-md flex text-xs font-light',
  delete: 'ml-2 self-center',
  item: 'bg-white border-brand px-2 py-1 rounded-md flex flex-wrap gap-2',
  label: 'font-medium text-nero text-sm',
  list: 'flex flex-wrap gap-2'
};

const SelectedRefinementTagList = ({
  clearDateFilter,
  isFilterApplied,
  setClearDateFilter
}) => (
  <div
    className={`flex flex-wrap ${isFilterApplied ? 'mt-4' : 'mt-0'} gap-2`}
    id='SelectedRefinementTagList'
  >
    <div
      onClick={() => {
        removeLocalStorageValue({
          keys: [
            LOCAL_STORAGE_KEYS.DATE_PICKER_VALUE,
            LOCAL_STORAGE_KEYS.DATE_RANGE
          ]
        });
        setClearDateFilter(!clearDateFilter);
      }}
    >
      <CurrentRefinements
        {...{
          includedAttributes: ['eventDateInSeconds'],
          transformItems: transformCurrentRefinementItemsLabelForOrderListing,
          classNames: {
            ...currentRefinementClassNameGroup,
            item: 'bg-white border-brand px-2 py-1 rounded-md'
          }
        }}
      />
    </div>
    <CurrentRefinements
      {...{
        classNames: { ...currentRefinementClassNameGroup },
        excludedAttributes: ['eventDateInSeconds', 'query'],
        transformItems: transformCurrentRefinementItemsLabelForOrderListing
      }}
    />
  </div>
);

export default SelectedRefinementTagList;
