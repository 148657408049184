import Image from 'next/image';

import { staticMediaStoreBaseURL } from '@/config/common';

const ErrorMessageWithIcon = ({ errorMessage, errorContainerClass }) => (
  <div className={`flex gap-2 ${errorContainerClass}`}>
    <Image
      alt='alert'
      className='cursor-pointer'
      height={0}
      src={`${staticMediaStoreBaseURL}/icons/alert-white.svg`}
      style={{ width: 14, height: 14 }}
      width={0}
    />
    <div className='text-sm'>{errorMessage}</div>
  </div>
);

export default ErrorMessageWithIcon;
