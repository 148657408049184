import Image from 'next/image';
import { memo, useState } from 'react';
import { useSortBy } from 'react-instantsearch';

import { staticMediaStoreBaseURL } from '@/config/common';

const SortOptions = ({ currentRefinement, handleOnChange, options }) => (
  <ul className='flex flex-col gap-4 p-5'>
    {options.map(({ label, value }, id) => (
      <li
        key={id}
        className='relative block searchkit-filter-menu-item cursor-pointer'
      >
        <label className='cursor-pointer'>
          <input
            {...{
              checked: currentRefinement === value,
              className:
                'checked:content-searchkit-filter-menu-item-checked searchkit-filter-menu-item-checked searchkit-radio',
              name: 'sortByOption',
              onChange: () => handleOnChange(value),
              type: 'radio',
              value
            }}
          />
          <span className='before:bg-searchkit-filter-menu-item empty-radio-span inline-block w-5 h-5'></span>
          <span className='text-sm text-dim-gray font-medium pl-3 self-center'>
            {label}
          </span>
        </label>
      </li>
    ))}
  </ul>
);

const ShowResultsCTA = ({ setIsSortDropdownOpen }) => (
  <div className='flex md:hidden justify-between text-xs font-medium border-t border-platinum px-5 py-3 bg-base-white'>
    <div className='text-brand self-center'></div>
    <div
      className='text-white bg-brand-gradient px-4 py-2 rounded-md'
      onClick={() => setIsSortDropdownOpen(false)}
    >
      Show Results
    </div>
  </div>
);

const SortResultsModal = memo(({ className, items }) => {
  const { currentRefinement, options, refine } = useSortBy({ items });

  const [isSortDropdownOpen, setIsSortDropdownOpen] = useState(false);

  const handleOnChange = (value) => {
    refine(value);
    setIsSortDropdownOpen(false);
  };

  return (
    <div className='relative border-platinum'>
      <div
        className={`md:h-13 w-8 md:w-12 cursor-pointer flex items-center ${className}`}
      >
        <Image
          {...{
            alt: 'sort-icon',
            onClick: () => setIsSortDropdownOpen(!isSortDropdownOpen),
            height: 0,
            src: `${staticMediaStoreBaseURL}/icons/selected-sort-by.svg`,
            style: { width: '100%', height: 52 },
            width: 0
          }}
        />
      </div>
      {isSortDropdownOpen && (
        <div>
          <div className='searchkit-sort-overlayer overlayer left-0 top-0 z-20'></div>
          <div
            className={`w-full md:w-72 border shadow-sm border-platinum rounded-t-3xl md:rounded-none bg-white fixed md:absolute z-[9] md:z-2 md:mt-2 searchkit-mobile-modal-popup`}
          >
            <div className='flex md:hidden justify-between text-base font-medium px-5 pt-4'>
              <div className='self-center'>Sort By</div>
              <div>
                <Image
                  {...{
                    alt: 'close-icon',
                    height: 0,
                    onClick: () => setIsSortDropdownOpen(false),
                    src: `${staticMediaStoreBaseURL}/icons/close-with-border-gray.svg`,
                    style: { width: 16, height: 16 },
                    width: 0
                  }}
                />
              </div>
            </div>
            <SortOptions
              {...{
                currentRefinement,
                handleOnChange,
                options
              }}
            />
            <ShowResultsCTA {...{ setIsSortDropdownOpen }} />
          </div>
        </div>
      )}
    </div>
  );
});

SortResultsModal.displayName = 'SortResultsModal';

export default SortResultsModal;
