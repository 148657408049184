import Image from 'next/image';
import PropTypes from 'prop-types';

import { staticMediaStoreBaseURL } from '@/config/common';

const TextArea = ({
  baseClasses = 'w-full border border-gray-200 rounded p-4 text-sm outline-none placeholder:text-grey-300',
  className = '',
  dbName,
  disabled,
  errorMessageClassName = 'text-xs text-red-500',
  errors,
  isResize = false,
  isShowErrorIcon,
  register,
  ...rest
}) => {
  const isInvalidMessage = errors?.[dbName]?.message;
  return (
    <>
      <textarea
        {...{
          className: `${baseClasses} ${className} ${
            isResize ? 'resize-y' : 'resize-none'
          } ${disabled === true ? 'cursor-not-allowed' : 'cursor-pointer'}`,
          disabled,
          ...register,
          ...rest
        }}
      />
      {isInvalidMessage && (
        <div className={errorMessageClassName}>
          {isShowErrorIcon && (
            <div>
              <Image
                alt='alert'
                className='cursor-pointer'
                height={0}
                src={`${staticMediaStoreBaseURL}/icons/alert-white.svg`}
                style={{ width: 14, height: 14 }}
                width={0}
              />
            </div>
          )}
          {isInvalidMessage}
        </div>
      )}
    </>
  );
};

export default TextArea;

TextArea.propTypes = {
  className: PropTypes.string,
  dbName: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.object,
  register: PropTypes.object
};
