import _ from 'lodash';

export const DELIVERY_PICKUP_DATE_FILTERS = [
  { key: 'all', label: 'All', sortOrder: 0, value: 'ALL' },
  {
    key: 'deliveryToday',
    label: 'Delivery Today',
    sortOrder: 1,
    value: 'DELIVERY_TODAY'
  },
  {
    key: 'deliveryTomorrow',
    label: 'Delivery Tomorrow',
    sortOrder: 2,
    value: 'DELIVERY_TOMORROW'
  },
  {
    key: 'pickupToday',
    label: 'Pickup Today',
    sortOrder: 3,
    value: 'PICKUP_TODAY'
  },
  {
    key: 'pickupTomorrow',
    label: 'Pickup Tomorrow',
    sortOrder: 4,
    value: 'PICKUP_TOMORROW'
  }
]
  .sort((a, b) => a.sortOrder - b.sortOrder)
  .map((a) => _.omit(a, 'sortOrder'));

export default DELIVERY_PICKUP_DATE_FILTERS; // TODO: To remove when add another const
