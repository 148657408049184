import { useInstantSearch } from 'react-instantsearch';

const NoResultsBoundary = ({ children, fallback }) => {
  const {
    results: { __isArtificial: isArtificial, nbHits }
  } = useInstantSearch();
  if (!isArtificial && nbHits === 0) {
    return fallback;
  }
  return children;
};

export default NoResultsBoundary;
