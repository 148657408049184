import Slider from 'rc-slider';
import { useEffect, useState } from 'react';
import { useRange } from 'react-instantsearch';

const RangeSliderRefinement = ({ title, ...props }) => {
  const { refine, canRefine, range, start } = useRange({
    ...props,
    precision: 2
  });
  const { min, max } = range;
  const [value, setValue] = useState([min, max]);

  const from = Math.max(min, Number.isFinite(start[0]) ? start[0] : min);
  const to = Math.min(max, Number.isFinite(start[1]) ? start[1] : max);

  useEffect(() => {
    setValue([from, to]);
  }, [from, to]);

  return (
    <div>
      <p className='text-sm font-light'>{title}</p>
      <p className='text-sm font-medium mb-3 mt-1'>
        AED {value[0]} to {value[1]}
      </p>
      <Slider
        {...{
          className: 'searchkit-range-selector',
          disabled: !canRefine,
          max,
          min,
          onAfterChange: (newValue) => refine(newValue),
          onChange: (newValue) => setValue(newValue),
          range,
          value
        }}
      />
    </div>
  );
};

export default RangeSliderRefinement;
