export const TENDER_STATUS = {
  ACTIVE: 'ACTIVE',
  EXPIRED: 'IN_ACTIVE'
};

export const BID_STATUS = {
  ACTIVE: { label: 'Active', value: 'ACTIVE' },
  EXPIRED: { label: 'Expired', value: 'EXPIRED' }
};

export const BID_SUBMISSION_STATUS = {
  PARTIALLY_SUBMITTED: {
    label: 'Partially Submitted',
    value: 'PARTIALLY_SUBMITTED'
  },
  PENDING: { label: 'Pending', value: 'PENDING' },
  SUBMITTED: { label: 'Submitted', value: 'SUBMITTED' }
};

export const BID_ITEM_STATUS = {
  ACCEPTED: { label: 'Accepted', value: 'ACCEPTED' },
  PENDING: { label: 'Pending', value: 'PENDING' },
  REJECTED: { label: 'Rejected', value: 'REJECTED' }
};

export const BID_ITEM_AVAILABILITY_STATUS = {
  AVAILABLE: { label: 'Available', value: 'AVAILABLE' },
  NOT_AVAILABLE: { label: 'Not Available', value: 'NOT_AVAILABLE' }
};

export const DELIVERY_MODE = {
  DRY_HIRE: { label: 'Dry Hire', value: 'DRY_HIRE' },
  NOT_APPLICABLE: { label: 'Not Applicable', value: 'NOT_APPLICABLE' },
  SELF_DELIVERY: { label: 'Self Delivery', value: 'SELF_DELIVERY' }
};

export const BID_REVISION_DELTA_TAG = {
  CART_ITEM_ADDED: {
    icon: 'icons/new-item.svg',
    label: 'New item(s) added',
    value: 'CART_ITEM_ADDED'
  },
  CART_ITEM_MODIFIED: {
    icon: 'icons/item-update.svg',
    label: 'Item(s) details updated',
    value: 'CART_ITEM_MODIFIED'
  },
  CART_ITEM_REMOVED: {
    icon: 'icons/item-remove.svg',
    label: 'Item(s) removed',
    value: 'CART_ITEM_REMOVED'
  },
  EVENT_INFO_MODIFIED: {
    icon: 'icons/event-update.svg',
    label: 'Event details updated',
    value: 'EVENT_INFO_MODIFIED'
  }
};
